import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

import { AddressDetails } from '@common/utils/hooks/google/utils';
import { Breakpoints } from '@common/utils/hooks/useWindowDimensions';
import { AnswerInput, SubjectsQuery_subjects as Subject } from '@typings/api';
import { LEGACY_SELF_SERVICE_PATH, SELF_SERVICE_PATH } from '@utils/constants';

export enum ActionType {
  CHANGE_CATEGORY = 'CHANGE_CATEGORY',
  DO_FORM_EFFECT = 'DO_FORM_EFFECT',
  RESET_INITIAL_STATE = 'RESET_INITIAL_STATE',
  RESET_FORM_INPUT = 'RESET_FORM_INPUT',
  SET_ANSWERS_ERROR = 'SET_ANSWERS_ERROR',
  SET_ANSWERS = 'SET_ANSWERS',
  SET_CALLBACK = 'SET_CALLBACK',
  SET_CUSTOMER_DIRTY = 'SET_CUSTOMER_DIRTY',
  SET_CUSTOMER_FORM_STATE = 'SET_CUSTOMER_FORM_STATE',
  SET_CUSTOMER_FORM_VALIDATION = 'SET_CUSTOMER_FORM_VALIDATION',
  SET_CUSTOMER_VALID = 'SET_CUSTOMER_VALID',
  SET_FLOW = 'SET_FLOW',
  SET_SUBJECT_ID = 'SET_SUBJECT_ID',
  SET_USED_ADDRESS = 'SET_USED_ADDRESS',
  SHOW_CHANGE_CATEGORY = 'SHOW_CHANGE_CATEGORY',
}

export enum FormType {
  QA_FORM,
  CUSTOMER_FORM,
  MAIN_FORM,
}

export enum FormState {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}

export enum FormValidation {
  PARTIAL,
  FULL,
}

export enum Flow {
  SELF_SERVICE = 'selfservice',
  BOOKING = 'booking',
  REFERRAL = 'referral',
  FORCED_REFERRAL = 'forced_referral',
  BLOCKED = 'blocked',
}

export interface ConfirmForCategory {
  categoryExternalKey: string | null;
  formInputKey: string;
}

export enum FormEffect {
  SUBMIT = 'SUBMIT',
  RESET = 'RESET',
}

export type Action =
  | { type: ActionType.CHANGE_CATEGORY; payload: string | null }
  | { type: ActionType.DO_FORM_EFFECT; payload: FormEffect | null }
  | { type: ActionType.RESET_FORM_INPUT; payload: string | null }
  | { type: ActionType.RESET_INITIAL_STATE }
  | { type: ActionType.SET_ANSWERS; payload: AnswerInput[] | null }
  | { type: ActionType.SET_ANSWERS_ERROR; payload: boolean }
  | { type: ActionType.SET_CALLBACK; payload: boolean }
  | { type: ActionType.SET_CUSTOMER_DIRTY; payload: boolean }
  | {
      type: ActionType.SET_CUSTOMER_FORM_STATE;
      payload: FormState;
    }
  | { type: ActionType.SET_CUSTOMER_FORM_VALIDATION; payload: FormValidation }
  | { type: ActionType.SET_CUSTOMER_VALID; payload: boolean }
  | { type: ActionType.SET_FLOW; payload: Flow }
  | { type: ActionType.SET_SUBJECT_ID; payload: string | null }
  | { type: ActionType.SET_USED_ADDRESS; payload: AddressDetails | null }
  | {
      type: ActionType.SHOW_CHANGE_CATEGORY;
      payload: ConfirmForCategory | null;
    };

interface State {
  answers: AnswerInput[] | null;
  callbackEnabled: boolean;
  categoryId: string | null;
  confirmForCategory: ConfirmForCategory | null;
  customerDirty?: boolean;
  customerFormState: FormState;
  customerFormSubmissionCount: number;
  customerFormValidation: FormValidation;
  customerValid?: boolean;
  flow: Flow;
  formEffect: FormEffect | null;
  qaHasErrors?: boolean;
  resetFormInput: string | null;
  subjectId: string | null;
  subjects: Subject[] | null;
  usedAddress: AddressDetails | null;
}

export const initialState: State = {
  answers: null,
  callbackEnabled: false,
  categoryId: null,
  confirmForCategory: null,
  customerDirty: false,
  customerFormState: FormState.EXPANDED,
  customerFormSubmissionCount: 0,
  customerFormValidation: FormValidation.PARTIAL,
  customerValid: false,
  flow:
    window.location.pathname.includes(SELF_SERVICE_PATH) ||
    window.location.pathname.includes(LEGACY_SELF_SERVICE_PATH)
      ? Flow.SELF_SERVICE
      : Flow.BOOKING,
  formEffect: null,
  qaHasErrors: false,
  resetFormInput: null,
  subjectId: null,
  subjects: null,
  usedAddress: null,
};

function bookingReducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.RESET_INITIAL_STATE:
      return initialState;
    case ActionType.SET_CALLBACK: {
      return {
        ...state,
        callbackEnabled: action.payload,
      };
    }
    case ActionType.SET_CUSTOMER_FORM_STATE:
      return {
        ...state,
        customerFormState: action.payload,
      };
    case ActionType.SET_CUSTOMER_FORM_VALIDATION:
      return {
        ...state,
        customerFormValidation: action.payload,
        customerFormState:
          action.payload === FormValidation.FULL &&
          !state.customerValid &&
          window.innerWidth <= Breakpoints.lg
            ? FormState.EXPANDED
            : state.customerFormState,
      };
    case ActionType.DO_FORM_EFFECT:
      return {
        ...state,
        formEffect: action.payload ?? null,
        customerFormSubmissionCount:
          action.payload === FormEffect.SUBMIT
            ? state.customerFormSubmissionCount + 1
            : state.customerFormSubmissionCount,
      };
    case ActionType.SHOW_CHANGE_CATEGORY:
      return {
        ...state,
        confirmForCategory: action.payload ?? null,
      };
    case ActionType.CHANGE_CATEGORY:
      return {
        ...state,
        categoryId: action.payload ?? null,
        confirmForCategory: null,
        customerFormSubmissionCount: 0,
        customerFormValidation: FormValidation.PARTIAL,
        customerValid: false,
      };
    case ActionType.SET_FLOW:
      return {
        ...state,
        flow: action.payload,
      };
    case ActionType.SET_SUBJECT_ID:
      return {
        ...state,
        subjectId: action.payload ?? null,
      };
    case ActionType.SET_ANSWERS:
      return {
        ...state,
        answers: action.payload ?? null,
      };
    case ActionType.SET_CUSTOMER_DIRTY:
      return {
        ...state,
        customerDirty: action.payload,
      };
    case ActionType.SET_CUSTOMER_VALID:
      return {
        ...state,
        customerValid: action.payload,
      };
    case ActionType.SET_ANSWERS_ERROR:
      return {
        ...state,
        qaHasErrors: action.payload,
      };
    case ActionType.SET_USED_ADDRESS:
      return {
        ...state,
        usedAddress: action.payload ?? null,
      };
    case ActionType.RESET_FORM_INPUT:
      return {
        ...state,
        resetFormInput: action.payload ?? null,
      };
    default:
      return state;
  }
}

const BookingContext = createContext<[State, React.Dispatch<Action>]>([
  initialState,
  () => {
    throw new Error(
      'Did you use useBookingContext() outside of the BookingContextProvider?',
    );
  },
]);

export function BookingContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [state, dispatch] = useReducer(bookingReducer, initialState);

  return (
    <BookingContext.Provider value={[state, dispatch]}>
      {children}
    </BookingContext.Provider>
  );
}

export function useBookingContext() {
  return useContext(BookingContext);
}
