import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';

import { InputFieldProps } from '../_common/types';

import Value from './Value';

export interface MultiSelectOption {
  label: string;
  value: string;
}

interface Props extends InputFieldProps<MultiSelectOption[]> {
  nullable?: boolean;
  options: MultiSelectOption[];
}

// === Main component === //
export default function MultiSelectField({
  className,
  disabled = false,
  nullable = false,
  value = [],
  name,
  onChange,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  options = [],
}: Props) {
  const { t } = useTranslation();
  const visibleOptions = useMemo(() => {
    return options.filter(({ value: optionValue }) => {
      return !value.find(({ value }) => optionValue === value);
    });
  }, [options, value]);

  const onChangeCallback = useCallback(
    (event: React.SyntheticEvent<HTMLSelectElement>) => {
      const selectedOptionIndex = options.findIndex(
        (option) => option.value === event.currentTarget.value,
      );
      onChange([...value, options[selectedOptionIndex]]);
    },
    [onChange, options, value],
  );
  const handleDelete = useCallback(
    (removedValue: string) => {
      const newValues = value.filter(({ value }) => value !== removedValue);
      onChange(newValues);
    },
    [value, onChange],
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Tab' && onMouseEnter) {
        onMouseEnter(event);
      }
    },
    [onMouseEnter],
  );

  const handleBlur = useCallback(
    (event) => {
      if (onMouseLeave && onBlur) {
        onMouseLeave(event);
        onBlur();
      }
    },
    [onMouseEnter, onBlur],
  );

  return (
    <div className={cx('relative w-full', className)}>
      <select
        aria-describedby={`${name}-error`}
        className="hide-ie-arrow:display-none absolute bottom-0 left-0 top-0 w-full appearance-none cursor-pointer"
        disabled={disabled}
        id={name}
        onBlur={handleBlur}
        onChange={onChangeCallback}
        onKeyUp={handleKeyPress}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        value=""
      >
        {!nullable && (
          <option value="">
            --- {t('translation:select.choose_option')} ---
          </option>
        )}
        {visibleOptions.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <div className="relative z-10 flex items-center px-4 py-2 w-full min-w-full min-h-16 bg-white border border-gray-300 pointer-events-none">
        <div className="flex flex-1 flex-wrap cursor-pointer pointer-events-none">
          {(value || []).map(({ label, value }) => (
            <Value key={value} onDelete={handleDelete} value={value}>
              {label}
            </Value>
          ))}
        </div>
        {!disabled && (
          <FontAwesomeIcon
            className="z-10 right-4 top-4 w-5 h-5 text-brand-500 pointer-events-none"
            icon={['fas', 'caret-down']}
          />
        )}
      </div>
    </div>
  );
}
