import { googleApiKey } from '../../../../config/config';

const BASE_URL = 'https://maps.googleapis.com/maps/api/timezone/json';

interface TimezoneResponse {
  dstOffset?: number;
  rawOffset?: number;
  status: google.maps.GeocoderStatus;
  timeZoneId?: string;
  timeZoneName?: string;
}

export async function getTimezone(
  coordinates: google.maps.LatLngLiteral,
): Promise<TimezoneResponse | undefined> {
  const timestamp = Date.now() / 1000;

  try {
    const response = await fetch(
      `${BASE_URL}?location=${coordinates.lat},${coordinates.lng}&timestamp=${timestamp}&key=${googleApiKey}`,
    );
    const result = await response.json();

    return result;
  } catch (error) {
    console.error(error);

    return undefined;
  }
}

export interface AddressDetails {
  house_number?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  country_short?: string;
  key?: string | null;
  place_name?: string;
  unit_number?: string;
  floor_number?: string;
  location?: google.maps.LatLngLiteral;
  state?: string;
  timezone?: string | null;
}

export interface AddressDetailsWithTimeZone extends AddressDetails {
  timezone: string;
}

/**
 * Extracts the address details from a GeocoderAddressComponent array
 * @param addressComponents The addressComponents array received from the PlaceResult
 */
export function extractAddressDetails(
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
) {
  return addressComponents.reduce<AddressDetails>(
    (addressDetails, { long_name, short_name, types }) => {
      if (types.includes('street_number')) {
        addressDetails.house_number = long_name;
      } else if (types.includes('route')) {
        addressDetails.street = long_name;
      } else if (types.includes('postal_code')) {
        addressDetails.postal_code = long_name;
      } else if (types.includes('locality')) {
        addressDetails.city = long_name;
      } else if (types.includes('country')) {
        addressDetails.country = long_name;
        addressDetails.country_short = short_name;
      } else if (types.includes('neighborhood')) {
        addressDetails.place_name = long_name;
      } else if (types.includes('floor')) {
        addressDetails.floor_number = long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressDetails.state = long_name;
      }

      return addressDetails;
    },
    {},
  );
}

export function mapAddressDetailsToSkedifyAddress(address: AddressDetails) {
  return {
    city: address.city,
    countryCode: address.country_short,
    coordinates: address.location,
    houseNumber: address.house_number,
    floorNumber: address.floor_number,
    postalCode: address.postal_code,
    street: address.street,
    state: address.state,
    unitNumber: address.unit_number,
  };
}

export async function getLocationInformation(
  result: google.maps.GeocoderResult[],
): Promise<
  | {
      country_short: string | undefined;
      location: google.maps.LatLngLiteral;
      timezone: string;
    }
  | undefined
> {
  if (result.length === 0) return;

  try {
    const { country_short } = extractAddressDetails(
      result[0].address_components,
    );
    const location = {
      lat: result[0]?.geometry?.location?.lat(),
      lng: result[0]?.geometry?.location?.lng(),
    };
    const { timeZoneId } = (await getTimezone(location)) ?? {};

    return {
      country_short,
      location,
      timezone: timeZoneId ?? DEFAULT_TIMEZONE,
    };
  } catch (error) {
    console.error(error);

    return undefined;
  }
}

export const DEFAULT_TIMEZONE =
  process.env.DEFAULT_TIMEZONE ?? 'Europe/Brussels';

/**
 * Formats an AddressDetails object to a single line string where the details are grouped accordingly and separated by specified separator (default is new line).
 * @param addressDetails The addressDetails to format
 * @param separator The separator to place between the address lines, defaults
 * to new line
 * @example
 * 'Lokerenveldstraat 57, 9300 Aalst, Belgium'
 */
export function mapAddressDetailsToString(
  {
    city = '',
    country = '',
    floor_number = '',
    house_number = '',
    place_name = '',
    postal_code = '',
    street = '',
    unit_number = '',
  }: AddressDetails,
  separator = '\n',
) {
  const floorNumber =
    floor_number.length > 0
      ? floor_number.padStart(floor_number.length + 1)
      : '';
  const unitNumber =
    unit_number.length > 0 ? unit_number.padStart(unit_number.length + 1) : '';
  const placeName =
    place_name.length > 0
      ? place_name.padStart(place_name.length + separator.length, separator)
      : '';

  const addressLine =
    `${street} ${house_number}${floorNumber}${unitNumber}`.trim();

  return `${addressLine}${placeName}${separator}${postal_code} ${city}${separator}${country}`.replace(
    /  +/g,
    ' ',
  );
}
