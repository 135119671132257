import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import { AUTHORIZE_FROM_CONTEXT } from '../../../modules/login/mutations';
import { AuthorizeFromContext } from '../../../types/api';
import { SELF_SERVICE_PATH } from '../../../utils/constants';
import {
  AuthMethod,
  clearToken,
  getDecodedToken,
  setToken,
  validateToken,
} from '../token';

import useFeatureFlag from './useFeatureFlag';
import useQueryParams from './useQueryParams';

export function useOneTimeToken() {
  const { id: contextId } = useQueryParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const isSelfService = pathname.includes(SELF_SERVICE_PATH);
  const token = getDecodedToken();

  const ottBookingEnabled = useFeatureFlag('ott.booking.enabled');
  const ottSelfServiceEnabled = useFeatureFlag('ott.selfservice.enabled');
  const ottEnabled = isSelfService ? ottSelfServiceEnabled : ottBookingEnabled;

  const [authorize] = useMutation<AuthorizeFromContext>(
    AUTHORIZE_FROM_CONTEXT,
    {
      variables: {
        contextId,
        isSelfService,
      },
      onCompleted: (data) => {
        if (data?.authorizeFromContext.token != null) {
          setToken(data.authorizeFromContext.token);
        }
      },
      onError: (error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('pathname', pathname);
          Sentry.captureException(error);
        });
        console.error(error);

        clearToken();
        history.push(pathname);
      },
    },
  );

  const authorizeFromContext = useCallback(
    async (contextId?: string | null) => {
      if (
        ottEnabled &&
        contextId != null &&
        token?.method !== AuthMethod.SSO &&
        token?.method !== AuthMethod.PLAIN &&
        token?.contextId !== contextId
      ) {
        await authorize({
          variables: {
            contextId,
            isSelfService,
          },
        });
      }
    },
    [authorize, isSelfService, ottEnabled],
  );

  useEffect(() => {
    (async function () {
      await authorizeFromContext(contextId);
    })();
  }, [authorizeFromContext, contextId]);

  useEffect(() => {
    if (ottEnabled) {
      validateToken(token, contextId);
    }
  }, [token, contextId, ottEnabled]);

  return authorizeFromContext;
}
