import { RefObject, useLayoutEffect } from 'react';

import useFeatureFlag from '@common/utils/hooks/useFeatureFlag';

export function useAutoscroll(ref?: RefObject<HTMLDivElement> | null) {
  const hasAutoScroll = useFeatureFlag('booking.main_form.auto_scroll');

  useLayoutEffect(() => {
    if (hasAutoScroll && ref?.current) {
      ref.current.scrollIntoView();
    }
  }, [hasAutoScroll, ref]);
}
