import React, { useCallback } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

import { InputFieldProps } from './_common/types';

const StyledInput = styled.input`
  cursor: pointer;

  :focus {
    border-color: ${({ theme }) => theme.colors.GREY_1};
  }

  :checked:after {
    content: '';
    margin-top: 4px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.BRAND};
  }
`;

interface Props extends InputFieldProps<boolean> {
  label: string | null;
  description?: string | null;
  required?: boolean;
}

export default function RadioButton({
  className,
  description,
  label,
  name,
  onChange,
  required,
  value: checked,
}: Props) {
  const handleChangeCallback = useCallback(() => {
    if (!checked) {
      onChange(true);
    }
  }, [checked, onChange]);

  return (
    <div>
      <div
        className={`${className} flex cursor-pointer`}
        onClick={handleChangeCallback}
      >
        <StyledInput
          checked={checked}
          className="relative flex flex-shrink-0 m-0 mr-2 w-5 h-5 border border-gray-300 rounded-full appearance-none"
          id={label || ''}
          name={name}
          onChange={handleChangeCallback}
          type="radio"
          value={label || ''}
        />
        <label
          className={clsx(
            'ml-1 cursor-pointer select-none',
            description == null ? 'text-gray-600' : 'text-gray-700',
          )}
          htmlFor={label || ''}
        >
          {label} &nbsp; {required && '*'}
        </label>
      </div>
      {description != null && (
        <div className="ml-8 mt-1 text-gray-500">{description}</div>
      )}
    </div>
  );
}
