import { useCallback, useState } from 'react';

import { country } from '../../../../config/config';

import { useGoogleLoader } from './useGoogleLoader';
import {
  AddressDetailsWithTimeZone,
  DEFAULT_TIMEZONE,
  extractAddressDetails,
  getTimezone,
} from './utils';

export function useGoogleAutocomplete(
  sessionToken: google.maps.places.AutocompleteSessionToken,
) {
  const { isLoaded } = useGoogleLoader();
  const [error, setError] = useState<
    undefined | 'no_results' | 'search_failed'
  >();
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const getPredictions = useCallback(
    async (input: string) => {
      setError(undefined);

      if (isLoaded && input) {
        const api = new google.maps.places.AutocompleteService();

        try {
          const result = await api.getPlacePredictions({
            input,
            sessionToken,
            componentRestrictions: { country },
          });

          setPredictions(result.predictions);
        } catch (error) {
          console.error(error);
          setError(error);
          setPredictions([]);
        }
      }
    },
    [isLoaded, sessionToken],
  );

  const resetPredictions = useCallback(() => {
    setPredictions([]);
  }, []);

  return {
    getPredictions,
    resetPredictions,
    predictions,
    error,
  };
}

export function getPlaceDetails(
  placeId: string,
  sessionToken: google.maps.places.AutocompleteSessionToken,
) {
  return new Promise<AddressDetailsWithTimeZone>((resolve, reject) => {
    new google.maps.places.PlacesService(
      document.createElement('div'),
    ).getDetails(
      {
        placeId,
        sessionToken,
        fields: ['address_component', 'geometry'],
      },
      async (details, status) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK ||
          details === null
        ) {
          return reject(status);
        }

        const { address_components, geometry } = details;
        const location = {
          lat: geometry?.location?.lat() ?? 0,
          lng: geometry?.location?.lng() ?? 0,
        };
        const timezone = await getTimezone(location);
        const address = extractAddressDetails(address_components);

        resolve({
          ...address,
          location,
          timezone: timezone?.timeZoneId ?? DEFAULT_TIMEZONE,
        });
      },
    );
  });
}
