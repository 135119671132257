import React from 'react';
import { FormSpy, useForm } from 'react-final-form';
import { AnyObject, FormState } from 'final-form';

import { ActionType, useBookingContext } from '@common/context/BookingContext';

export function AutoSave() {
  const form = useForm();
  const [, dispatch] = useBookingContext();

  function handleBlur({
    active,
    dirty,
    dirtySinceLastSubmit,
    valid,
  }: FormState<AnyObject>) {
    if (active === undefined && valid && (dirty || dirtySinceLastSubmit)) {
      form.submit();
    }

    if (active === undefined) {
      // https://github.com/final-form/react-final-form/issues/809
      setTimeout(
        () =>
          dispatch({
            type: ActionType.SET_CUSTOMER_VALID,
            payload: valid,
          }),
        0,
      );
    }
  }

  return (
    <FormSpy
      onChange={handleBlur}
      subscription={{
        active: true,
        dirty: true,
        dirtySinceLastSubmit: true,
        valid: true,
      }}
    />
  );
}
