import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  ActionType,
  Flow,
  useBookingContext,
} from '@common/context/BookingContext';
import useFeatureFlag from '@common/utils/hooks/useFeatureFlag';
import { BOOKING_PATH, SELF_SERVICE_PATH } from '@utils/constants';

import { useCategoryContext } from '../context/CategoryContext';
import Label from '../fields/_common/label';
import SelectField from '../fields/SelectField';

type Props = {
  label: string;
  name: string;
};

function CategorySelector({ label, name }: Props) {
  const [{ flow }, dispatch] = useBookingContext();
  const history = useHistory();
  const { pathname } = useLocation();
  const { categories, category, onChangeCategory } = useCategoryContext();
  const shouldClearFields = useFeatureFlag('category_selection.clear.enabled');

  const onChange = useCallback(
    (categoryId: string) => {
      const newCategory = categories.find(
        (category) => category.id === categoryId,
      );

      if (newCategory !== undefined) {
        onChangeCategory(newCategory);

        if (shouldClearFields) {
          dispatch({
            type: ActionType.RESET_INITIAL_STATE,
          });

          return history.push({
            pathname:
              flow === Flow.SELF_SERVICE || pathname.includes(SELF_SERVICE_PATH)
                ? SELF_SERVICE_PATH
                : BOOKING_PATH,
            search: categoryId ? `?categoryId=${categoryId}` : undefined,
          });
        }
      }
    },
    [
      categories,
      flow,
      onChangeCategory,
      dispatch,
      history,
      pathname,
      shouldClearFields,
    ],
  );

  return (
    <>
      <div className="hidden">
        <Label className="leading-none" htmlFor={name}>
          {label}
        </Label>
      </div>
      <SelectField
        name={name}
        nullable={false}
        onChange={onChange}
        value={category?.id}
      >
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.label}
          </option>
        ))}
      </SelectField>
    </>
  );
}

export default CategorySelector;
