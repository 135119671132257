import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';

import { FeatureFlagQuery } from '@typings/api';
import {
  BOOKING_PATH,
  CONFIRM_PATH,
  SELF_SERVICE_PATH,
} from '@utils/constants';

import { Flow, getDecodedToken } from '../token';

import { FEATURE_FLAGS } from './useFeatureFlag/queries';
import useFeatureFlag from './useFeatureFlag';

export enum ConversionAnalyticsProvider {
  TEALIUM = 'Tealium',
}

export function useConversionAnalytics() {
  const location = useLocation();
  const conversionAnalyticsEnabled = useFeatureFlag(
    CONVERSION_ANALYTICS_FEATURE_FLAG,
  );
  const [isInitialized, setIsInitialized] = useState(false);

  const { data } = useQuery<FeatureFlagQuery>(FEATURE_FLAGS, {
    variables: { userControllable: null },
    skip: !conversionAnalyticsEnabled,
  });

  const { provider, script } = useMemo(() => {
    const featureFlag = (data?.featureFlags ?? []).find(
      (featureFlag) => featureFlag.key === CONVERSION_ANALYTICS_FEATURE_FLAG,
    );

    return {
      provider: featureFlag?.featureFlagSettings.find(
        (setting) => setting.key === CONVERSION_ANALYTICS_PROVIDER,
      )?.value,
      script: featureFlag?.featureFlagSettings.find(
        (setting) => setting.key === CONVERSION_ANALYTICS_SCRIPT,
      )?.value,
    };
  }, [data]);

  useEffect(() => {
    if (script?.trim() && !isInitialized) {
      appendScript(script);

      setTimeout(() => {
        setIsInitialized(true);
      }, 1000);
    }
  }, [script, isInitialized]);

  useEffect(() => {
    if (isInitialized && provider) {
      const { getTrackFn } =
        PROVIDER_MAP[provider as ConversionAnalyticsProvider];

      trackView(location.pathname, getTrackFn());
    }
  }, [location.pathname, isInitialized, provider]);
}

export const CONVERSION_ANALYTICS_FEATURE_FLAG = 'conversion_analytics.enabled';
export const CONVERSION_ANALYTICS_PROVIDER =
  'conversion_analytics.setting.provider';
export const CONVERSION_ANALYTICS_SCRIPT =
  'conversion_analytics.setting.script';

const TRACKED_PAGES = [SELF_SERVICE_PATH, BOOKING_PATH, CONFIRM_PATH];

interface Provider {
  getTrackFn: () => ((args: any) => void) | undefined;
}

const PROVIDER_MAP: Record<ConversionAnalyticsProvider, Provider> = {
  [ConversionAnalyticsProvider.TEALIUM]: {
    // FIXME: the returned function here throws
    // Uncaught TypeError: this.track is not a function in utag.js
    // Is "this" unbound? Tried with a regular function() {} and same issue
    getTrackFn: () => window.utag?.view,
  },
};

function trackView(pathname: string, trackFn?: (args: any) => void) {
  try {
    // TODO: why does trackFn not work here? (see above)
    if (window.utag && TRACKED_PAGES.includes(pathname)) {
      const token = getDecodedToken();
      const currentPage = pathname.replace('/', '');
      // @ts-ignore
      const data = utag_data_init ?? window.utag_data ?? {};

      window.utag.view({
        ...data,
        converted: pathname === CONFIRM_PATH,
        flow: token?.flow ?? Flow.SELF_SERVICE,
        page_name: data.page_name
          ? `${data.page_name} | ${currentPage}`
          : currentPage,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function appendScript(script: string) {
  const tag = document.createElement('script');

  tag.type = 'text/javascript';
  tag.innerHTML = script; // TODO: can we catch invalid scripts?

  document.body.appendChild(tag);

  return tag;
}
