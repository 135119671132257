import * as Sentry from '@sentry/browser';
import axios from 'axios';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';

import { API_URL } from '../..';

import { getSessionId } from './session';

const TOKEN_KEY = 'token';

export enum AuthMethod {
  PLAIN = 'plain',
  SSO = 'SSO',
  CONTEXT = 'context',
}

export enum Flow {
  BOOKING = 'booking',
  SELF_SERVICE = 'selfservice',
}

export interface DecodedToken {
  id: string;
  contextId?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  iat: number;
  exp: number;
  flow: Flow;
  roles: string[];
  method: AuthMethod;
  accessLevel: number;
}

export const setToken = (token: string) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope((scope) => {
      const user = decode(token) as DecodedToken;
      if (user) {
        scope.setUser({
          id: user.id,
          sessionId: getSessionId(),
        });
      }
    });
  }

  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (token === null) {
    const cookie = Cookies.get('token');
    if (cookie !== undefined) {
      localStorage.setItem(TOKEN_KEY, cookie);
      Cookies.remove('token');

      return cookie;
    }
  }

  return token;
};

export const clearToken = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: undefined,
      });
    });
  }

  localStorage.removeItem(TOKEN_KEY);
};

export const getDecodedToken = (): DecodedToken | null => {
  const token = getToken();

  if (!token) {
    return null;
  }

  return decode(token) as DecodedToken;
};

export const validateToken = (
  token: DecodedToken | null,
  contextId?: string | null,
): boolean => {
  if (token === null) {
    return false;
  }

  if (Date.now() > token.exp * 1000) {
    clearToken();

    return false;
  }

  if (token.method === AuthMethod.CONTEXT && token.contextId != contextId) {
    clearToken();

    return false;
  }

  return true;
};

export const shouldRefreshToken = (token: DecodedToken | null): boolean => {
  if (token !== null && Date.now() > token.exp * 1000) {
    return true;
  }

  return false;
};

export const logout = async () => {
  const token = getToken();

  try {
    await axios.post(
      `${API_URL}/logout`,
      {},
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.error(error);
  } finally {
    clearToken();
  }
};
