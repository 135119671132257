import { useCallback } from 'react';

import { useGoogleLoader } from './useGoogleLoader';

export function useGoogleGeocoder() {
  const { isLoaded } = useGoogleLoader();

  return useCallback(
    async (address: string): Promise<google.maps.GeocoderResult[]> => {
      if (!isLoaded || !address) {
        return [];
      }

      const api = new google.maps.Geocoder();

      try {
        const result = await api.geocode({
          address,
        });

        return result.results;
      } catch (error) {
        console.error(error);

        return [];
      }
    },
    [isLoaded],
  );
}
