import React, { useCallback } from 'react';
import cx from 'clsx';
import { sanitize } from 'dompurify';
import styled from 'styled-components';

import { InputFieldProps } from './_common/types';

interface Props extends InputFieldProps<boolean> {
  label: string;
  isLabelHidden?: boolean;
  required?: boolean;
}

const LinkWrapper = styled.span`
  > a {
    color: rgb(96, 165, 250);
    text-decoration: underline;
  }
`;

const StyledInput = styled.input`
  cursor: pointer;

  :focus {
    border-color: ${({ theme }) => theme.colors.GREY_1};
  }

  :checked:after {
    content: '';
    transform: rotate(45deg);
    border-right: 3px solid;
    border-bottom: 3px solid;
    border-color: ${({ theme }) => theme.colors.BRAND};
    box-sizing: border-box;
    position: absolute;
    bottom: 5px;
    left: 5px;
    height: 12px;
    width: 8px;
  }
`;

export default function Checkbox({
  className,
  isLabelHidden = false,
  label,
  onChange,
  required,
  value: checked,
}: Props) {
  const handleChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onChange(!checked);
    },
    [checked, onChange],
  );
  const sanitizedLabel = sanitize(label);

  return (
    <div className={`${className ?? ''} flex items-center`}>
      <StyledInput
        aria-describedby={`${label}-error`}
        checked={checked}
        className="relative flex flex-shrink-0 m-0 mr-2 w-5 h-5 border border-gray-300 appearance-none cursor-pointer"
        id={label}
        onChange={(event) => handleChangeCallback(event)}
        type="checkbox"
        value={label}
      />

      <label
        className={cx(
          'ml-1 text-gray-700 cursor-pointer',
          isLabelHidden && 'hidden',
        )}
        htmlFor={sanitizedLabel}
      >
        <LinkWrapper dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
        &nbsp;
        {required && '*'}
      </label>
    </div>
  );
}
