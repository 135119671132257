import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styled from 'styled-components';

import { Map, Marker } from '@common/Map';
import {
  getPlaceDetails,
  useGoogleAutocomplete,
} from '@common/utils/hooks/google/useGoogleAutocomplete';
import { AddressDetailsWithTimeZone } from '@common/utils/hooks/google/utils';

import { SecondaryButton } from '../../button';
import useDebounce from '../../utils/hooks/useDebounce';
import { ErrorMessage } from '../_common/errorMessage';

interface Props {
  disabled?: boolean;
  location?: google.maps.LatLngLiteral;
  name: string;
  onChangeAddress: (address: AddressDetailsWithTimeZone) => void;
  onChangeSearchString: (searchString: string) => void;
  onError?: () => void;
  searchString: string;
  showMap?: boolean;
}

export default function GoogleMapsSearch({
  disabled = false,
  location,
  name,
  onChangeAddress,
  onChangeSearchString,
  onError,
  searchString,
  showMap = true,
}: Props) {
  const { t } = useTranslation();

  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken(),
  );
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);
  const { error, getPredictions, predictions, resetPredictions } =
    useGoogleAutocomplete(sessionToken);

  const addressSearchRef = useRef<HTMLInputElement>(null);
  const debouncedSearchTerm = useDebounce<string>(searchString, 500);

  async function handleSelect(
    prediction: google.maps.places.AutocompletePrediction,
  ) {
    await fetchDetails(prediction.place_id);

    onChangeSearchString(prediction.description);
    resetPredictions();
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
    setShowSuggestions(false);
  }

  const fetchDetails = useCallback(
    async (placeId: string) => {
      const location = await getPlaceDetails(placeId, sessionToken);

      return onChangeAddress(location);
    },
    [onChangeAddress, sessionToken],
  );

  useEffect(() => {
    if (disabled === false && debouncedSearchTerm) {
      getPredictions(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, getPredictions, disabled]);

  useEffect(() => {
    if (error && onError) {
      onError();
    }
  }, [error, onError]);

  useEffect(() => {
    if (!disabled) {
      addressSearchRef?.current?.focus();
    }
  }, [addressSearchRef, disabled]);

  return (
    <>
      <input
        autoComplete="off"
        className={clsx(
          'outline-none mt-2 p-3 w-full leading-normal border border-gray-300 focus:border-gray-500 cursor-pointer transition duration-200 ease-out',
          disabled
            ? 'bg-gray-300 cursor-default text-gray-700'
            : 'bg-white cursor-text text-gray-700',
        )}
        disabled={disabled}
        id={name}
        onChange={(event) => onChangeSearchString(event.target.value)}
        onFocus={() => setShowSuggestions(true)}
        placeholder={t(
          'booking:customer_form.address_modal.search_placeholder',
        )}
        ref={addressSearchRef}
        value={searchString}
      />

      {showSuggestions && (
        <div className="flex flex-wrap">
          <Suggestions
            disabled={disabled}
            error={error}
            onSelect={handleSelect}
            predictions={predictions}
            searchString={searchString}
          />
        </div>
      )}
      {showMap && (
        <div className={clsx('relative mt-4 h-60')}>
          <Map center={location}>
            {(map) => location && <Marker map={map} position={location} />}
          </Map>
        </div>
      )}
    </>
  );
}

interface SuggestionsProps {
  predictions: google.maps.places.AutocompletePrediction[];
  error: 'no_results' | 'search_failed' | undefined;
  searchString: string;
  disabled?: boolean;
  onSelect: (prediction: google.maps.places.AutocompletePrediction) => void;
}

function Suggestions({
  disabled,
  error,
  onSelect,
  predictions,
  searchString,
}: SuggestionsProps) {
  const { t } = useTranslation();

  if (!searchString || disabled) {
    return null;
  }

  if (error) {
    <ErrorMessage>
      {t(`booking:customer_form.address_modal.${error}`)}
    </ErrorMessage>;
  }

  return (
    <>
      {predictions.map((prediction) => (
        <SuggestionButton
          key={prediction.place_id}
          onClick={() => onSelect(prediction)}
          type="button"
        >
          <span className="overflow-ellipsis whitespace-no-wrap overflow-hidden">
            {prediction.description}
          </span>
        </SuggestionButton>
      ))}
    </>
  );
}

const SuggestionButton = styled(SecondaryButton)`
  min-width: 100%;
  margin-top: 0.5rem;

  &:focus {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.WHITE};
  }
  &:focus::before {
    width: ${({ disabled }) => (disabled ? '0' : '100%')};
  }

  ${({ theme }) => theme.breakPoints.medium} {
    min-width: 0;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
